import React, {Component} from 'react'
import './../assets/css/DrawerToggle.css'

class DrawerToggle extends Component {
    render() {
        return (
            <div>
                <button className="toggle-button" onClick={this.props.click}>
                    <div className="toggle-button_line"/>
                    <div className="toggle-button_line"/>
                    <div className="toggle-button_line"/>
                </button>
            </div>
        );
    }
}

export default DrawerToggle;