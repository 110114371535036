import React,{Component} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Captcha } from 'primereact/captcha';

class LoginForm extends Component{

    showResponse = (response)=>{
        this.props.formValues.isValid = true;
    }

    render(){
        return(
            <form autoComplete="off" onSubmit={this.props.onSubmit}>
                <div className="p-col-fixed p-mt-4">
                <div className="p-grid p-fluid">
                        <div className="p-col-12 p-mt-3">
                            <InputText 
                                value={this.props.formValues.ruc}
                                name="ruc"
                                onChange={this.props.onChange}
                                type="text" 
                                placeholder="RUC"/>
                        </div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-mt-3">
                            <InputText 
                                value={this.props.formValues.usuario}
                                name="usuario"
                                onChange={this.props.onChange}
                                type="text" 
                                placeholder="Usuario"/>
                        </div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-mt-3">
                            <InputText 
                                value={this.props.formValues.password}
                                name="password"
                                onChange={this.props.onChange}
                                type="password"
                                placeholder="Contraseña"/>
                        </div>
                    </div>
                    <div className="card p-mt-3">
                        <Captcha 
                            // siteKey="6LcSJtgZAAAAAJMnW7B5lVApMbfycAOKXvLDgWJB" 
                            siteKey="6LdkJxEcAAAAAHWOkWu1N8iEl9NnQyhBMK7v0IJs"
                            onResponse={this.showResponse}/>
                    </div>
                    <div className="p-d-flex p-p-3 p-mt-1 p-ml-auto">
                        <Button 
                            label={this.props.Request ? "Cargando..." : "Ingresar"}
                            disabled={this.props.Request}
                            onClick={this.handleClick}
                            className="p-ml-auto"
                            style={{
                                background:'#4D80E4',
                                border: '1px solid #4D80E4'}}/>
                    </div>
                </div>
            </form>
        );
    }
}

export default LoginForm;

