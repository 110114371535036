import React, {Component} from 'react'
import DrawerToggle from './DrawerToggle';
import {EliminarTodo,getDecode} from '../../Services/LocalStorage'
import SlideDrawer from '../components/SlideDrawer'
import BackDrop from './BackDrop.js'

import './../assets/css/Navigation.css'

class Navbar extends Component {

    constructor(){
        super();
        this.state = {
            time : '',
            openSideDrawer : false
        }
    }

    componentDidMount(){
        this.timerID = setInterval(
            () => this.calcHorToken(),
            1000
          );
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }

    cerrarSesion(){
        EliminarTodo();
        window.location.reload(true);
    }

    calcHorToken(){
        var date = new Date(getDecode().exp * 1000);
        var hoy = new Date();

        var diff = Math.round((  date - hoy ) / 1000);
        if(diff < 0){
            alert("Debe iniciar sesión nuevamente.")
            EliminarTodo();
            window.location.reload(true);
        }else{
            var time = diff;
            var hours = Math.floor( time / 3600 );  
            var minutes = Math.floor( (time % 3600) / 60 );
            var seconds = time % 60;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;
            var result = hours + ":" + minutes + ":" + seconds;
            this.setState({time : result});
        }
    }

    render() {
        return (
            <>
                <header className="toolbar">
                    <nav className="toolbar_navigation">
                        <div>
                            <DrawerToggle click={() => this.setState({openSideDrawer : !this.state.openSideDrawer})}/>
                        </div>
                        <div className="toolbar_logo">
                            <a>Uniflex</a>
                        </div>
                        <div className="spacer"/>
                        <div className="toolbar_navigation_items">
                            <ul>
                                <li style={{color:'white'}}>{this.state.time}</li>
                                <li><a onClick={this.cerrarSesion}>Salir</a></li>
                            </ul>
                        </div>
                    </nav>
                </header>
                {
                    this.state.openSideDrawer ?
                    <>
                        <SlideDrawer/>
                        <BackDrop click={() => this.setState({openSideDrawer : !this.state.openSideDrawer})}/>
                    </>
                    :
                    <></>
                }
            </>
        );
    }
}

export default Navbar;