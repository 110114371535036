import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getDecode } from '../../Services/LocalStorage'
import { initAxiosInterceptors, url } from '../../Services/Axios'
import DTSincronizacion from '../components/DTSincronizacion'
import ModalEntidad from '../components/ModalSincronizacion'

import '../assets/css/Modal.css'
import 'react-responsive-modal/styles.css'
import { Component } from 'react'

initAxiosInterceptors();

class Sincronizacion extends Component {

    meses = [];
    anios = [];
    cantidadAnios = 2;
    basicData = {};

    InicializarValores() {
        const fecha = new Date();
        for (let index = 1; index <= 12; index++) {
            fecha.setMonth(index - 1);
            this.meses.push({ code: index, name: fecha.toLocaleString('default', { month: 'long' }) });
        }

        let anio = fecha.getFullYear();
        for (var i = anio; i > anio - this.cantidadAnios; i--) {
            this.anios.push(i);
        }

        var mesesNombre = this.meses.map(function (item) {
            return item['name'];
        });

        this.basicData = {
            labels: mesesNombre,
            datasets: [
                {
                    label: 'Totales',
                    backgroundColor: '#42A5F5',
                    //data: [65, 59, 80, 81, 56, 55, 40]
                    data: [0, 0, 0, 0, 0, 0, 0]
                },
                {
                    label: 'Sincronizados',
                    backgroundColor: '#FFA726',
                    //data: [28, 48, 40, 19, 86, 27, 90]
                    data: [0, 0, 0, 0, 0, 0, 0]
                }
            ]
        };
    }

    validate = values => {
        const errores = {};
        var mensaje = 'Requerido';
        if (!values.ArchivoAdjunto.Nombre) {
            errores.ArchivoAdjunto = mensaje;
        }
        if (!values.NombrePublicacion) {
            errores.NombrePublicacion = mensaje;
        }
        if (!values.EmpresaRUC) {
            errores.Empresa = mensaje;
        }
        return errores;
    }

    NuevoFormulario() {
        let fechaActual = new Date();
        const formulario = {
            ID: 0,
            RUC: '',
            RazonSocial: '',
            Anio: fechaActual.getFullYear(),
            Mes: {
                codito: fechaActual.getMonth() + 1,
                namesito: fechaActual.toLocaleString('default', { month: 'long' })
            },
            TipoDocumento: "CP",
            TipoDocumentoResultados: [],
            MesResultados: [],
            Paginado: {
                NumeroPagina: 1,
                RegistrosxPagina: 10,
                NumeroPaginas: 0,
            },
            Comprobantes: [], //Los comprobantes para la página elegida
            FiltroTipoConsulta: 0,
            TextoTipoConsulta: '',
        };

        return formulario;
    }

    state = {
        form: this.NuevoFormulario(),
        openModal: false,
        listaEntidad: [],
        listaEmpresa: [],
        listaAnio: this.anios,
        listaTipoDocumento: ['CP', 'RC', 'RA'],
        listaMes: this.meses,
        errores: {}
    }

    getLightTheme() {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        return {
            basicOptions,
            horizontalOptions
        }
    }

    //Archivos
    SubirArchivo = (e, fileUpload) => {
        const file = e.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                ID: 0,
                Nombre: file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion: '',
                Tamanio: file.size,//parseFloat(file.size / Math.pow(1024,2)).toFixed(2), //De Bytes a MegaBytes
                Archivo: encoded,
                Extension: file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }

            this.setState({
                form: {
                    ...this.state.form,
                    ArchivoAdjunto: oArchivoAdjunto,
                    RutaLogo: oArchivoAdjunto.Nombre
                }
            })
        }
        reader.onerror = error => {
            alert(error)
        }
        fileUpload.current.files = ''
        fileUpload.current.state.files = ''
    }

    BorrarArchivo = () => {
        this.setState({
            form: {
                ...this.state.form,
                ArchivoAdjunto: {},
                RutaLogo: ""
            }
        })
    }

    DescargarArchivo = event => {
        let lArchivos = this.state.ItemBackLog.ArchivosAdjuntos
        if (lArchivos.filter((e) => { return e === event }).length > 0) {
            lArchivos = lArchivos.filter((e) => { return e === event })
            const obj = lArchivos[0]
            const byteCharacters = atob(obj.Archivo)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', obj.Nombre + '.' + obj.Extension);
            document.body.appendChild(link);
            link.click();
        } else {
            alert('Error: No se encontro el archivo indicado')
        }
    }

    componentDidMount() {
        var entidad = getDecode();
        this.InicializarValores();
        if (entidad.Administrador = 1) {
            this.ListarConfiguracion();
            //this.ListarEmpresa();
        } else {
            this.ObtenerConfiguracion();
            this.state.openModal = true;
        }
    }

    ListarConfiguracion = () => {
        axios.get(url() + 'SincronizacionWeb')
            .then((response) => {
                if (response.status == 200) {
                    this.setState({ listaEntidad: response.data });
                } else {
                    alert(response.data.data);
                }
            }).catch((error) => {
                console.log("error" + error);
                if (error.response) {
                    if (error.response.data) alert(error.response.data.error);
                    console.log("Imprimiendo el error");
                    console.log("Error response" + error.response);
                } else {
                    console.log(error);
                    alert("Error desconocido, consulte con el administrador.");
                }
            })
    }

    ObtenerSincronizacion = (e) => {
        console.log(e);
        let objConsulta = new Object();
        this.state.form.RUC = e.RUC;
        this.state.form.RazonSocial = e.RazonSocial;
        this.state.form.FiltroTipoConsulta = 0;

        objConsulta.RUC = this.state.form.RUC;
        objConsulta.Anio = this.state.form.Anio;
        objConsulta.Mes = this.state.form.Mes.codito;
        objConsulta.TipoDocumento = this.state.form.TipoDocumento;
        objConsulta.TipoConsulta = this.state.form.FiltroTipoConsulta;
        this.asignarTextoTipoConsulta(objConsulta.TipoConsulta);
        axios.post(url() + 'SincronizacionWeb/GetData', objConsulta)
            .then((response) => {
                if (response.status == 200) {
                    this.setState({
                        form: {
                            ...this.state.form,
                            MesResultados: response.data.MesResultados,
                            TipoDocumentoResultados: this.procesarTipoDocumentoResultados(response.data.TipoDocumentoResultados)
                        },
                        openModal: true
                    });
                } else {
                    alert(response.data.data);
                }
            }).catch((error) => {
                console.log("error" + error);
                if (error.response) {
                    if (error.response.data) alert(error.response.data.error);
                    console.log("Imprimiendo el error");
                    console.log("Error response" + error.response);
                } else {
                    console.log(error);
                    alert("Error desconocido, consulte con el administrador.");
                }
            })
    }

    asignarTextoTipoConsulta(tipoConsulta) {
        let valor = "--";
        switch (tipoConsulta) {
            case 1:
                this.state.form.TextoTipoConsulta = this.state.form.TipoDocumento + "-Todos"
                break;
            case 2:
                this.state.form.TextoTipoConsulta = this.state.form.TipoDocumento + "-Sincronizados";
                break;
            case 3:
                this.state.form.TextoTipoConsulta = this.state.form.TipoDocumento + "-Pendientes"
                break;
            default:
                this.state.form.TextoTipoConsulta = "";
                break;
        }
    }

    procesarTipoDocumentoResultados(tipoDocumentoResultados) {
        let itemTotal = [];
        let itemSincronizados = [];
        if (tipoDocumentoResultados) {
            for (let index = 1; index <= 12; index++) {
                let item = tipoDocumentoResultados.find(item => item.Mes == index);
                if (item != null) {
                    itemTotal.push(item.CantidadTotal);
                    itemSincronizados.push(item.CantidadProcesada);
                } else {
                    itemTotal.push(0);
                    itemSincronizados.push(0);
                }
            }
        }

        this.basicData.datasets[0].data = itemTotal;
        this.basicData.datasets[1].data = itemSincronizados;
    }

    handleChange = (e) => {        
        var cForm;
        switch (e.target.name) {
            case 'Mes':
                console.log('Mes');
                cForm = {
                    ...this.state.form,
                    Mes: {
                        ...this.state.form.Mes,
                        codito: e.target.value.code,
                        namesito: e.target.value.name
                    },
                    Comprobantes: [],
                    Paginado: {
                        NumeroPagina: 1,
                        RegistrosxPagina: 10,
                        NumeroPaginas: 0,
                    }
                }
                break;
            default:
                cForm = {
                    ...this.state.form,
                    [e.target.name]: e.target.value,
                    Comprobantes: [],
                    Paginado: {
                        NumeroPagina: 1,
                        RegistrosxPagina: 10,
                        NumeroPaginas: 0,
                    }                    
                };
                break;
        }

        this.setState({
            form: cForm
        }, () => this.ObtenerSincronizacion({ RUC: this.state.form.RUC, RazonSocial: this.state.form.RazonSocial }));
    }

    handlePaginado = (tipoPaginado) => {
        console.log('handlePaginado');
        console.log(this.state.form);
        switch (tipoPaginado) {
            case 1:
                this.setState({
                    form: {
                        ...this.state.form,
                        Paginado: {
                            ...this.state.form.Paginado,
                            NumeroPagina: 1,
                            RegistrosxPagina: 10,
                            NumeroPaginas: 0
                        }
                    }
                }, () => this.onConsultar(this.state.form.FiltroTipoConsulta))
                break;
            case 2:
                this.setState({
                    form: {
                        ...this.state.form,
                        Paginado: {
                            ...this.state.form.Paginado,
                            NumeroPagina: this.state.form.Paginado.NumeroPagina - 1,
                        }
                    }
                }, () => this.onConsultar(this.state.form.FiltroTipoConsulta))
                break;
            case 3:
                this.setState({
                    form: {
                        ...this.state.form,
                        Paginado: {
                            ...this.state.form.Paginado,
                            NumeroPagina: this.state.form.Paginado.NumeroPagina + 1,
                        }
                    }
                }, () => this.onConsultar(this.state.form.FiltroTipoConsulta))
                break;
            case 4:
                this.setState({
                    form: {
                        ...this.state.form,
                        Paginado: {
                            ...this.state.form.Paginado,
                            NumeroPagina: this.state.form.Paginado.NumeroPaginas,
                        }
                    }
                }, () => this.onConsultar(this.state.form.FiltroTipoConsulta))
                break;
            default:
                break;
        }

    }

    handleRegistrosChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                Paginado: {
                    ...this.state.Paginado,
                    NumeroPagina: 1,
                    RegistrosxPagina: e.value
                }
            }
        }, () => this.onConsultar(this.state.form.FiltroTipoConsulta))
    }

    onConsultar = (tipoConsulta, desdeBoton = false) => {
        let objConsulta = new Object();
        this.state.form.FiltroTipoConsulta = tipoConsulta;
        if (desdeBoton) this.state.form.Paginado.NumeroPagina = 1;

        objConsulta.RUC = this.state.form.RUC;
        objConsulta.Anio = this.state.form.Anio;
        objConsulta.Mes = this.state.form.Mes.codito;
        objConsulta.TipoConsulta = this.state.form.FiltroTipoConsulta;
        objConsulta.TipoDocumento = this.state.form.TipoDocumento;
        objConsulta.Paginado = this.state.form.Paginado;
        this.asignarTextoTipoConsulta(objConsulta.TipoConsulta);
        axios.post(url() + 'SincronizacionWeb/GetData', objConsulta)
            .then((response) => {
                if (response.status == 200) {
                    this.setState({
                        form: {
                            ...this.state.form,
                            Comprobantes: response.data.Comprobantes,
                            Paginado: {
                                ...this.state.form.Paginado,
                                NumeroPaginas: response.data.NumeroPaginas
                            }
                        }
                    });
                }
            }).catch((error) => {
                alert(error.message);
            });
    }

    onActualizar = (e, tipoActualizacion) => {
        e.preventDefault();
        let objConsulta = new Object();
        this.state.form.FiltroTipoConsulta = 0;
        this.state.form.Paginado.NumeroPagina = 1;
        this.state.form.Paginado.NumeroPaginas = 0;

        objConsulta.RUC = this.state.form.RUC;
        objConsulta.Anio = this.state.form.Anio;
        objConsulta.Mes = this.state.form.Mes.codito;
        objConsulta.TipoConsulta = this.state.form.FiltroTipoConsulta;
        objConsulta.TipoDocumento = this.state.form.TipoDocumento;
        objConsulta.Paginado = this.state.form.Paginado;
        this.asignarTextoTipoConsulta(objConsulta.TipoConsulta);

        if (tipoActualizacion == 1) {
            axios.post(url() + 'SincronizacionWeb', objConsulta)
                .then((response) => {
                    if (response.status == 200) {
                        this.setState({
                            form: {
                                ...this.state.form,
                                Comprobantes: [],
                                MostrarMensaje: true
                            }
                        });
                    }
                }).catch((error) => {
                    alert(error.message);
                });
        } else if (tipoActualizacion == 2) {
            axios.put(url() + 'SincronizacionWeb', objConsulta)
                .then((response) => {
                    if (response.status == 200) {
                        this.setState({
                            form: {
                                ...this.state.form,
                                Comprobantes: [],
                                MostrarMensaje: false
                            }
                        });
                    }
                }).catch((error) => {
                    alert(error.message);
                });
        }
    }

    render() {
        return (
            <div>
                <DTSincronizacion
                    ListaEntidad={this.state.listaEntidad}
                    openModal={() => this.setState({ openModal: true })}
                    handleSelectRow={(e) => {
                        this.ObtenerSincronizacion(e);
                    }} />
                <ModalEntidad
                    entidad={this.state.form}
                    openModal={this.state.openModal}
                    closeModal={() => {
                        this.setState({ openModal: false, form: this.NuevoFormulario() });
                        this.setState({ errores: [] });
                    }}
                    listaEmpresa={this.state.listaEmpresa}
                    listaAnio={this.state.listaAnio}
                    listaTipoDocumento={this.state.listaTipoDocumento}
                    listaMes={this.state.listaMes}
                    handleChange={this.handleChange}
                    subirArchivo={this.SubirArchivo}
                    onConsultar={this.onConsultar}
                    onActualizar={this.onActualizar}
                    Errores={this.state.errores}
                    basicOptionsChart={this.getLightTheme().basicOptions}
                    dataChart={this.basicData}
                    handlePage={this.handlePaginado}
                    handleRegistroChange={this.handleRegistrosChange}
                />
            </div>
        )
    }
}

export default Sincronizacion;