import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import Login from "./Login/pages/Login"
import Inicio from "./Emision/pages/Inicio"
import UsuarioP from "./Emision/pages/Usuario"


import {getAuthenticado, getAuthenticadoImp, getDecode} from './Services/LocalStorage'
import NavBarE from './Emision/components/Navbar'
//Seguridad
import Usuarios from './Seguridad/Pages/Usuarios'
import Area from './Seguridad/Pages/Area'
import Empleado from './Seguridad/Pages/Empleado'
import ConfiguracionWeb from "./Emision/pages/Configuracion"
import SincronizacionWeb from "./Emision/pages/Sincronizacion"

import './Login/assets/css/App.css';

const rSeguridad = ({ match }) => (
    <>
        <Route path={`${match.path}/`} component={Usuarios} exact />
        {/* <Route path={`${match.path}/Area`} component={Area} />
        <Route path={`${match.path}/Empleado`} component={Empleado} /> */}
    </>
)

class App extends Component {
    constructor(){
        super();
        this.state={
            sideDrawerOpen: false
        }
    } 
    
    render(){
        const PrivateRoute = ({component: Component, authed, ...rest}) => {
            return (
                <Route
                    {...rest}
                    render={props => (authed === true ? 
                        <>
                            <NavBarE/>
                            <Route
                                path="/Emision" 
                                render={({ match: { url } }) => (
                                    <>
                                        {/* <Route path={`${url}/`} component={UsuarioP} exact /> */}
                                        <Route path={`${url}/`} component={Inicio} exact />
                                        <Route path={`${url}/ConfiguracionWeb`} component={ConfiguracionWeb}/>                                        
                                        <Route path={`${url}/SincronizacionWeb`} component={SincronizacionWeb}/>
                                        {getDecode().Administrador === 'True' ? 
                                            <Route path={`${url}/Seguridad`} component={rSeguridad} />
                                            :
                                            <></>}
                                    </>
                                )}
                            />
                        </>
                    :
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} 
                />
            )
        }

        return (
            //<BrowserRouter  basename={'/emisionweb'}>                       
            <BrowserRouter  basename=""> 
            
                <Switch>
                <Route exact path="/" component={Login}></Route>     
                <PrivateRoute 
                    authed={getAuthenticado()} 
                    path="/Emision" 
                    component={Inicio}/>
                </Switch>
            </BrowserRouter>
            );
        }
    }
    
export default App;