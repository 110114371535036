import React,{useEffect, useState} from 'react'
import axios from 'axios'
import { initAxiosInterceptors, url } from '../../Services/Axios'
import DTAreas from '../Components/DTAreas'
import ModalArea from '../Components/ModalArea'

import '../Assets/DTable.css'

initAxiosInterceptors();

export default function Area(){

    const validarArea = values =>{
        const errores ={}
        if(!values.Codigo){
            errores.Codigo  = 'Codigo es requerido';
        }
        if(!values.Nombre){
            errores.Nombre = 'Nombre es requerido'
        }
        return errores;
    }

    const cArea = {
        PKID : 0,
        Codigo : '',
        Nombre : ''
    }

    const [ openModal, setOpenModal ] = useState(false)
    const [ listaArea, setListaArea ] = useState([])
    const [ area, setArea ] = useState(cArea)
    const [ errores, setErrores ] = useState({})

    useEffect(function (){
        ListarArea()
    },[])

    const ListarArea = () => {
        axios.post(url()+'Area/ListarAreas')
        .then( (response) => {
            if(response.data.success){
                setListaArea(JSON.parse(response.data.data))
            }else{
                alert(response.data.data)
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    const handleChange = (e) => {
        setArea({
            ...area,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const{errores,...sinErrores} = area;
        const result = validarArea(sinErrores);
        if(Object.keys(result).length){
            return setErrores(result)
        }else{
            CrearActualizarArea()
        }
    }

    const CrearActualizarArea = () => {
        axios.post(url()+'Area/CrearActualizarArea',area)
        .then( (response) => {
            alert(response.data.data)
            if(response.data.success){
                setOpenModal(false)
                setArea(cArea)
                ListarArea()
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    return(
        <div>
            <DTAreas
                ListaArea = {listaArea}
                openModal = {() => setOpenModal(true)}
                handleSelectRow = { (e) =>{
                    setArea(e)
                    setOpenModal(true)
                }}/>
            <ModalArea
                openModal = {openModal}
                closeModal = {() => {
                    setErrores({})
                    setArea(cArea)
                    setOpenModal(false)
                }}
                handleChange = {handleChange}
                area = {area}
                onSubmit = {onSubmit}
                Errores = {errores}/>
        </div>
    )
}