import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'

export default function DTUsuarios(props){

    const Administrador = (rowData) => {
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={rowData.Administrador}
                    disabled/>
            </div>
        )
    }

    const header = (
        <div className="p-d-flex p-jc-between">
            <h4>Usuarios</h4>
            <Button 
                label="Agregar Usuario" 
                onClick={props.openModal} 
                autoFocus/>
        </div>
    );

    return(
        <div className="datatable-responsive-demo">
            <DataTable 
                value={props.ListaUsuarios} 
                className="p-datatable-responsive-demo" 
                onSelectionChange={e => props.handleSelectRow(e.value)} 
                selectionMode="single"
                paginator 
                rows={8} 
                header={header}>
                <Column field="RUC" header="RUC"/>
                <Column field="Nombre" header="Nombre"/>
                <Column field="Apellido" header="Apellido"/>
                <Column field="IDUsuario" header="Usuario"/>
                <Column field="Correo" header="Correo"/>
                <Column field="Administrador" body={Administrador} header="Adm."/>                
            </DataTable>
        </div>
    )
}