import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import LoginForm from '../components/LoginForm'
import ModalCambioContraseña from '../components/ModalCambioContraseña'
import { setToken, EliminarTodo, getDecode } from '../../Services/LocalStorage'
import './../assets/css/Login.css'
import { initAxiosInterceptors, url } from '../../Services/Axios'

initAxiosInterceptors();

export default function Login(){

    const cForm = {
        grant_type: '',
        code: '',
        redirect_uri: '',
        ruc: '',
        usuario: '',
        password: '',
        refresh_token: '',
        isValid: false
    }

    const cCambioContraseña = {
        IDUsuario : 0,
        AntiguaContrasena : '',
        NuevaContrasena : '',
        RepetirNuevaContrasena : ''
    } 

    const [ form, setForm ] = useState(cForm)
    const [ CambioContraseña, setCambioContraseña ] = useState(cCambioContraseña)
    const [ openModal, setOpenModal ] = useState(false)
    const [ Request, setRequest] = useState(false)

    useEffect(function (){
        EliminarTodo()
    },[])

    useEffect(function (){
        if(CambioContraseña.IDUsuario !== 0) setOpenModal(true)
    },[CambioContraseña])

    const consultarUsuario = () => {
        axios.post(url()+'Login/Autenticar',form)
        .then((response) => {
            if(response.status==200){
                setToken(response.data.access_token,response.data.refresh_token);
                if(getDecode().Nuevo === 'True'){ 
                    var CContraseña = {
                        ...CambioContraseña,
                        IDUsuario : getDecode().sub
                    }
                    setCambioContraseña(CContraseña)
                } else 
                {
                   window.location.assign(getDecode().MenuPrincipal);
                }
            }else{
                alert(response.data)
            }
        })
        .catch((error) => {
            if (error.response) {
                alert(error.response.data.error.message);
                console.log("Imprimiendo el error");
                console.log(error.response.data);
            }else {
                console.log(error);
                alert("Error desconocido, consulte con el administrador.");
            }
        })
        .finally( () => {
            setRequest(false)
        })
    }

    const handleChange = e => {
        var hForm = {
            ...form,
            [e.target.name]: e.target.value
        }
        setForm(hForm)
    };

    const handleChangeContrasena = e => {
        var hConstrasena = {
            ...CambioContraseña,
            [e.target.name]: e.target.value
        }
        setCambioContraseña(hConstrasena)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if(form.isValid){
            setRequest(true)
            consultarUsuario()
        }else{
            alert("Confirma que no eres un robot")
        }
    }

    const aCambioContraseña = () => {
        axios.post(url()+'Usuario/CambioPassword',CambioContraseña)
        .then((response) => {
            if(response.status==200){
                window.location.assign(getDecode().MenuPrincipal);
            }else{
                alert(response.data);
            }
        })
        .catch((error) => {
            alert(error);
        })
        .finally(() => {
            setRequest(false);
        })
    }

    const SubmitContrasena = async e => {
        e.preventDefault()
        if(CambioContraseña.NuevaContrasena !== CambioContraseña.RepetirNuevaContrasena) alert("Las nuevas contraseñas son distintas.")
        else 
            if(CambioContraseña.AntiguaContrasena === CambioContraseña.NuevaContrasena) alert("No se puede utilizar la antigua contraseña, como nueva.")
            else 
                if(validarExpresion(CambioContraseña.NuevaContrasena)){
                    setRequest(true)
                    aCambioContraseña()
                } 
                else 
                    alert("No se acepta la contraseña, la contraseña debe contener: \n 1. Minimo de 8 caracteres y maximo de 15 caracteres. \n 2. Al menos una letra mayúscula, minuscula. \n 3. Al menos un digito numerico. \n 4. No se aceptan espacios en blanco. \n 5. Al menos un caracter especial (@, $, !, %, *, ?, &).")
    }

    const validarExpresion = (Contrasena) => {
        var Band = false
        var regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/)
        if(regex.test(Contrasena)) Band = !Band
        return Band
    }

    const LimpiarContraseña = () => {
        EliminarTodo()
        setForm(cForm)
        setCambioContraseña(cCambioContraseña)
        setOpenModal(false)
    }

    return(
        <div className="authBox">
            <div className="LeftBox">
                <div className="imageAuth"></div>
            </div>
            <div className="RightBox">
                <div className="box">
                    <div className="titleAuth">Uniflex</div>
                     <LoginForm
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        formValues={form}
                        Request = {Request}/>
                </div>
            </div>
            <ModalCambioContraseña
                openModal = {openModal}
                closeModal = {LimpiarContraseña}
                onChangeContrasena = {handleChangeContrasena}
                ValuesContrasena = {CambioContraseña}
                onSubmitContrasena ={SubmitContrasena}
                Request = {Request}/>
        </div>
    );
}
