import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Modal } from 'react-responsive-modal'

export default function  ModalCambioContraseña(props){
    
    return(
        <Modal
            open={props.openModal}
            onClose={props.closeModal}
            center>
            <form autoComplete="off" onSubmit={props.onSubmitContrasena}>
                <div className="headerModal">
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Cambio de contraseña</h2>
                </div>  
                <div className="modal-body p-mt-3">
                    <div className="p-grid nested-grid">
                        <div className="p-col-12">
                            <div className="p-grid p-fluid ">
                                <div className="p-col">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Contraseña antigua</label>
                                    <InputText 
                                        name="AntiguaContrasena"
                                        type="password"
                                        value={props.ValuesContrasena.AntiguaContrasena}
                                        onChange={props.onChangeContrasena}/>    
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-fluid ">
                                <div className="p-col">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Contraseña nueva</label>
                                    <InputText 
                                        name="NuevaContrasena"
                                        type="password"
                                        value={props.ValuesContrasena.NuevaContrasena}
                                        onChange={props.onChangeContrasena}/>    
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-fluid ">
                                <div className="p-col">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Repetir contraseña nueva</label>
                                    <InputText 
                                        name="RepetirNuevaContrasena"
                                        type="password"
                                        value={props.ValuesContrasena.RepetirNuevaContrasena}
                                        onChange={props.onChangeContrasena}/>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="p-d-flex footer">
                    <Button 
                        label = { props.Request ? "Cargando..." : "Aceptar" } 
                        style = {{
                            background : '#4D80E4',
                            border : '1px solid #4D80E4'}}
                        icon = { props.Request ? "" : "pi pi-check" }
                        className = "p-ml-auto"
                        disabled = { props.Request }
                        autoFocus />
                </div>
            </form>
        </Modal>
        )
    }