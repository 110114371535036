import React,{Component} from 'react'
import axios from 'axios'
import HeaderInicio from '../components/HeaderInicio'
import BodyInicio from '../components/BodyInicio'
import ModalCambioContraseña from '../../Login/components/ModalCambioContraseña'
import { url } from '../../Services/Axios'
import { getDecode, EliminarTodo} from '../../Services/LocalStorage'

const cCambioContraseña = {
    IDUsuario : getDecode() ? getDecode().sub : 0,
    AntiguaContrasena : '',
    NuevaContrasena : '',
    RepetirNuevaContrasena : ''
} 

export default class Inicio extends Component{

    constructor(){
        super();
        this.state = {
            CambioContraseña : cCambioContraseña,
            openModal: false,
            Request: false,
            Usuario: {
                ID : 0,
                IDUsuario : '',
                IsNuevo : false,
                Administrador : false,        
                Empresa : {   
                    RUC : '',
                    RazonSocial : ''
                },
                Nombre: '',
                Apellido: '',
                Correo: '',
                RUC: '',
            }
        }
    }

    componentDidMount(){
        this.ConsultarUsuario()
    }
    
    async ConsultarUsuario(){        
        var IDUsuario = getDecode() ? getDecode().sub : 0
        axios.get(url()+'Usuario/' + IDUsuario)
        .then( (response) => {
            if(response.status==200){
                this.setState({Usuario : response.data})
            }else{
                throw new Error(response.data);
            }
        })
        .catch( (error) => {
            alert(error);
        })
    }

    LimpiarContraseña = () => {
        this.setState({
            CambioContraseña: cCambioContraseña,
            openModal: false
        })
    }

    HandleChangeContrasena = e => {
        this.setState({
            CambioContraseña:{
                ...this.state.CambioContraseña,
                [e.target.name]: e.target.value
            }
        })
    }

    SubmitContrasena = async e => {
        e.preventDefault()
        if(this.state.CambioContraseña.NuevaContrasena !== this.state.CambioContraseña.RepetirNuevaContrasena) alert("Las nuevas contraseñas son distintas.")
        else 
            if(this.state.CambioContraseña.AntiguaContrasena === this.state.CambioContraseña.NuevaContrasena) alert("No se puede utilizar la antigua contraseña, como nueva.")
            else 
                if(this.validarExpresion(this.state.CambioContraseña.NuevaContrasena)){
                    this.setState({
                        Request: true
                    },() => this.aCambioContraseña())
                } 
                else alert("No se acepta la contraseña, la contraseña debe contener: \n 1. Minimo de 8 caracteres y maximo de 15 caracteres. \n 2. Al menos una letra mayúscula, minuscula. \n 3. Al menos un digito numerico. \n 4. No se aceptan espacios en blanco. \n 5. Al menos un caracter especial (@, $, !, %, *, ?, &).")
    }

    validarExpresion = (Contrasena) => {
        var Band = false
        var regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/)
        if(regex.test(Contrasena)) Band = !Band
        return Band
    }

    async aCambioContraseña(){
        axios.post(url()+'Usuario/CambioPassword',this.state.CambioContraseña)
        .then((response) => {
            if(response.status==200){
                alert(response.data.Descripcion);
                EliminarTodo();
                window.location.reload(true);
            }else{
                alert(response.data.data);
            }
        })
        .catch((error) => {
            alert(error)
        })
        .finally(() => {
            this.setState({Request : false})
        })
    }

    render(){
        return(
            <div>
                <HeaderInicio/>
                <BodyInicio
                    Usuario={this.state.Usuario}
                    OpenModal={() => this.setState({openModal: true})}/>
                <ModalCambioContraseña
                    openModal = {this.state.openModal}
                    closeModal = {this.LimpiarContraseña}
                    onChangeContrasena = {this.HandleChangeContrasena}
                    ValuesContrasena = {this.state.CambioContraseña}
                    onSubmitContrasena ={this.SubmitContrasena}
                    Request = {this.state.Request}/>
            </div>
        )
    }
}