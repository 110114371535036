import React,{Component} from 'react'

export default class HeaderInicio extends Component{

    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return(
            <div className="p-col-12">
                <div className="CrumbBeetwen">
                    <div className="p-d-flex">
                        <ul className="breadcrumb">
                            <li>Inicio</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}