import React, { useRef, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { Paginator } from './Paginator.tsx'

export default function ModalSincronizacion(props) {

    const [disabled, setDisabled] = useState(true);

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 2
            }}
        />
    );

    return (
        <Modal
            open={props.openModal}
            onClose={props.closeModal}
            center>
            <div className="headerModal">
                <h2 style={{
                    color: '#4D80E4',
                    fontFamily: 'Sarala',
                    fontSize: '22px',
                    textAlign: 'center'
                }}>
                    {props.entidad.RUC ? props.entidad.RUC : ''} - {props.entidad.RazonSocial ? props.entidad.RazonSocial : ''}  </h2>
            </div>
            <div className="modal-body p-mt-3">
                <div className="p-grid nested-grid">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-10">

                            </div>
                            <div className="p-col-2">
                                <Dropdown
                                    value={props.entidad.Anio}
                                    options={props.listaAnio}
                                    onChange={props.handleChange}
                                    name="Anio"
                                    placeholder="Seleccione año" />
                                {props.Errores.Anio && <small className="p-invalid p-d-block">{props.Errores.Anio}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <ColoredLine color="blue" />
                    </div>
                    <div className="p-col-1">
                        <Dropdown
                            value={props.entidad.TipoDocumento}
                            options={props.listaTipoDocumento}
                            onChange={props.handleChange}
                            name="TipoDocumento"
                            placeholder="Seleccione TipoDocumento" />
                        {props.Errores.Mes && <small className="p-invalid p-d-block">{props.Errores.Mes}</small>}
                    </div>
                    <div className="p-col-5">
                        <Chart type="bar" data={props.dataChart} options={props.basicOptionsChart} />
                    </div>
                    <div className="p-col-1">
                        <Dropdown
                            value={{
                                code: props.entidad.Mes ? props.entidad.Mes.codito : '',
                                name: props.entidad.Mes ? props.entidad.Mes.namesito : ''
                            }}
                            options={props.listaMes}
                            onChange={props.handleChange}
                            name="Mes"
                            optionLabel="name"
                            placeholder="Seleccione Mes" />
                        {props.Errores.Mes && <small className="p-invalid p-d-block">{props.Errores.Mes}</small>}
                    </div>
                    <div className="p-col-5">
                        <div className="p-col-12">
                            <DataTable value={props.entidad.MesResultados} responsiveLayout="scroll" size="small" emptyMessage="No existen registros">
                                <Column field="TipoDocumento" header="Tipo Doc."></Column>
                                <Column field="CantidadTotal" header="Totales"></Column>
                                <Column field="CantidadProcesada" header="Sincronizados"></Column>
                                <Column field="CantidadPendiente" header="Pendientes"></Column>
                            </DataTable>
                        </div>

                        <div className="p-col-12">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-6">
                                    <Button
                                        type="button"
                                        label="Reprocesar"
                                        style={{
                                            background: '#4D80E4',
                                            border: '1px solid #4D80E4',
                                            alignContent: 'end'
                                        }}
                                        onClick={(e) => props.onActualizar(e, 1)}
                                        autoFocus />
                                </div>
                                <div className="p-col-6">
                                    <Button
                                        type="button"
                                        label="Actualizar registros"
                                        style={{
                                            background: '#4D80E4',
                                            border: '1px solid #4D80E4',
                                            alignContent: 'end'
                                        }}
                                        onClick={(e) => props.onActualizar(e, 2)}
                                        autoFocus />
                                </div>
                            </div>
                        </div>

                        {props.entidad.MostrarMensaje
                            ? <div className="p-col-12">
                                <label style={{ fontWeight: "bold" }}>Espere, unos minutos hasta que termine de procesar, luego haga click en el botón "Actualizar registros".</label>
                            </div>
                            : null
                        }

                    </div>
                    <div className="p-col-12">
                        <ColoredLine color="blue" />
                    </div>
                    <div className="p-col-12">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-2">
                                <label style={{ fontWeight: "bold" }}>{props.entidad.Mes.namesito}-{props.entidad.TextoTipoConsulta}</label>
                            </div>
                            <div className="p-col-7">

                            </div>
                            <div className="p-col-1">
                                <Button
                                    type="button"
                                    label="Todos"
                                    style={{
                                        background: '#4D80E4',
                                        border: '1px solid #4D80E4',
                                        fontSize: 11,
                                        alignContent: 'end'
                                    }}
                                    onClick={() => props.onConsultar(1, true)}
                                    autoFocus />
                            </div>
                            <div className="p-col-1">
                                <Button
                                    type="button"
                                    label="Sincronizados"
                                    style={{
                                        background: '#4D80E4',
                                        border: '1px solid #4D80E4',
                                        fontSize: 11,
                                        alignContent: 'end'
                                    }}
                                    onClick={() => props.onConsultar(2, true)}
                                    autoFocus />
                            </div>
                            <div className="p-col-1">
                                <Button
                                    type="button"
                                    label="Pendientes"
                                    style={{
                                        background: '#4D80E4',
                                        border: '1px solid #4D80E4',
                                        fontSize: 11,
                                        alignContent: 'end'
                                    }}
                                    onClick={() => props.onConsultar(3, true)}
                                    autoFocus />
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div>
                            <div className="card">
                                <DataTable value={props.entidad.Comprobantes} responsiveLayout="scroll" size="small" emptyMessage="No existen registros">
                                    <Column field="TipoDocumento" header="TipoDocumento"></Column>
                                    <Column field="NumeroCp" header="NumeroCp"></Column>
                                    <Column field="FechaEmision" header="Fecha"></Column>
                                    <Column field="Total" header="Total"></Column>
                                    <Column field="EstadoSunat" header="EstadoSunat"></Column>
                                </DataTable>
                                <Paginator
                                    Paginado={props.entidad.Paginado}
                                    HandlePreviousLL={() => props.handlePage(1)}
                                    RegistrosPagina={props.entidad.Paginado.RegistrosxPagina}
                                    HandlePrevious={() => props.handlePage(2)}
                                    HandleFollowing={() => props.handlePage(3)}
                                    HandleFollowingLL={() => props.handlePage(4)}
                                    OnRegistroChange={props.handleRegistroChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <ColoredLine color="blue" />
                    </div>
                </div>
            </div>
        </Modal>
    )
}