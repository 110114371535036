import React,{Component} from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button';

import '../assets/css/Inicio.css'
import '../assets/css/ListTrello.css'

export default class BodyInicio extends Component{

    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return(
            <div className="ContainerTrello">
                <div className="p-col-12 p-md-8 p-lg-6 p-xl-4">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh', background:'white'}}>
                            <div className="TopBox">
                                <div className="Image"></div>
                            </div>
                            <div className="BottonBox"> 
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col">
                                                <label 
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '19px'}}>
                                                Nombre</label>
                                                <InputText 
                                                    name="Nombre"
                                                    type="text" 
                                                    value={this.props.Usuario.Nombre}
                                                    disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col">
                                                <label 
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '19px'}}>
                                                Razon Social</label>
                                                <InputText 
                                                    name="Area"
                                                    type="text"
                                                    value={this.props.Usuario.Empresa.RazonSocial}
                                                    disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col">
                                                <label 
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '19px'}}>
                                                Correo Electronico</label>
                                                <InputText 
                                                    name="Correo"
                                                    type="text"
                                                    value={this.props.Usuario.Correo} 
                                                    disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col">
                                            <Button 
                                                type="button"
                                                label="Cambio contraseña"
                                                onClick={this.props.OpenModal}
                                                style={{
                                                    background:'#4D80E4',
                                                    border: '1px solid #4D80E4'}}
                                                className="p-ml-auto"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}