import React, {Component} from 'react'
import { getDecode} from '../../Services/LocalStorage'
import './../assets/css/SlideDrawer.css'

class SlideDrawer extends Component {
    render() {
        return (
            <nav className="slide-drawer">
                <ul>
                    <li className="Children"><a href="/Emision/ConfiguracionWeb">Configuración</a></li>
                    <li className="Children"><a href="/Emision/SincronizacionWeb">Sincronización</a></li>
                    {getDecode().Administrador === 'True' ? 
                        <li className="SubMenu">
                            <a className="aSubMenu">Seguridad</a>
                            <ul>
                                <li className="Children"><a href="/Emision/Seguridad">Usuario</a></li>
                                {/* <li className="Children"><a href="/Emision/Seguridad/Empleado">Empleado</a></li>
                                <li className="Children"><a href="/Emision/Seguridad/Area">Area</a></li> */}
                            </ul>
                        </li>
                        :
                        <></>
                    }
                    <li className="Children"><a href="/">Salir</a></li>
                </ul>
            </nav>
        );
    }
}

export default SlideDrawer;