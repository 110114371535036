import React,{useEffect, useState} from 'react'
import axios from 'axios'
import {getDecode} from '../../Services/LocalStorage'
import { initAxiosInterceptors, url } from '../../Services/Axios'
import DTConfiguracion from '../components/DTConfiguracion'
import ModalEntidad from '../components/ModalConfiguracion'

import '../assets/css/Modal.css'
import 'react-responsive-modal/styles.css'
import { Component } from 'react'

initAxiosInterceptors();

class Configuracion extends Component{

    validate = values =>{
        const errores ={};
        var mensaje='Requerido';
        if(!values.ArchivoAdjunto.Nombre){
            errores.ArchivoAdjunto  = mensaje;
        }
        if(!values.NombrePublicacion){
            errores.NombrePublicacion = mensaje;
        }
        if(!values.EmpresaRUC){
            errores.Empresa = mensaje;
        }
        return errores;
    }

    NuevoFormulario() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy+ '-' + mm + '-' + dd ;
        const formulario={
            ID : 0,        
            RutaLogo : '',
            TiposComprobantePago : '',
            NombrePublicacion: '',
            SincronizarRegistroVentas : false,
            RVConfiguracion : {   
                ID : 0,
                HoraInicio : 0,
                HoraFin : 6,
                FechaMinima : today,
                CantidadMinima: 0
            },
            Empresa : {   
                RUC : '',
                RazonSocial : ''
            },
            EmpresaRUC: '',
            ArchivoAdjunto: {}
        }
        return formulario;
    }

    state = {
        form : this.NuevoFormulario(),
        openModal: false,
        listaEntidad: [],
        listaEmpresa: [],
        errores: {}
    }

    //Archivos
    SubirArchivo = (e,fileUpload) =>  {
        const file = e.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                ID : 0,
                Nombre : file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion : '',
                Tamanio : file.size,//parseFloat(file.size / Math.pow(1024,2)).toFixed(2), //De Bytes a MegaBytes
                Archivo : encoded,
                Extension : file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            
            this.setState({
                form :{
                    ...this.state.form,
                    ArchivoAdjunto: oArchivoAdjunto,
                    RutaLogo: oArchivoAdjunto.Nombre
                }
            })
        }
        reader.onerror = error => {
            alert(error)
        }
        fileUpload.current.files = ''
        fileUpload.current.state.files = ''
    }

    BorrarArchivo = () =>  {
        this.setState({
            form :{
                ...this.state.form,
                ArchivoAdjunto: {},
                RutaLogo: ""
            }
        })
    }
    
    DescargarArchivo = event => {
        let lArchivos = this.state.ItemBackLog.ArchivosAdjuntos
        if(lArchivos.filter( (e) => { return e === event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e === event})
            const obj = lArchivos[0]
            const byteCharacters = atob(obj.Archivo)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', obj.Nombre + '.' + obj.Extension);
            document.body.appendChild(link);
            link.click();
        }else{
            alert('Error: No se encontro el archivo indicado')
        }
    }
    

    componentDidMount(){
        var entidad=getDecode();
        if (entidad.Administrador=1) {
            this.ListarConfiguracion();
            this.ListarEmpresa();    
        }else {
            this.ObtenerConfiguracion();
            this.state.openModal=true;
            //setOpenModal(true);
        }        
    }

    ListarConfiguracion = () => {
        axios.get(url()+'ConfiguracionWeb')
        .then((response) => {
            if(response.status==200){
                this.setState( { listaEntidad:response.data} );
            }else{
                alert(response.data.data);
            }
        }).catch((error) => {
            console.log("error"+error);
            if (error.response) {
                if (error.response.data) alert(error.response.data.error);
                console.log("Imprimiendo el error");
                console.log("Error response"+error.response);
            }else {
                console.log(error);
                alert("Error desconocido, consulte con el administrador.");
            }
        })
    }

    ObtenerConfiguracion = (e) => {
        axios.get(url()+'ConfiguracionWeb/'+e.ID)
        .then((response) => {
            if(response.status==200){                
                this.setState({ form: response.data 
                ,openModal: true });                
                console.log(this.state);
            }else{
                alert(response.data.data);
            }
        }).catch((error) => {
            console.log("error"+error);
            if (error.response) {
                if (error.response.data) alert(error.response.data.error);
                console.log("Imprimiendo el error");
                console.log("Error response"+error.response);
            }else {
                console.log(error);
                alert("Error desconocido, consulte con el administrador.");
            }
        })
    }

    ListarEmpresa = () => {
        axios.get(url()+'Empresa/Listar')
        .then((response) => {
            if(response.status==200){
                var aEmpresa = []
                Object.keys(response.data).forEach(function(key){
                    var iEmpresa = {
                        code: response.data[key].RUC,
                        name: response.data[key].RazonSocial,
                    }
                    aEmpresa.push(iEmpresa);
                });
                this.state.listaEmpresa=aEmpresa;
            }else{
                alert(response.data.data);
            }
        }).catch((error) => {
            alert(error);
        })
    }

    handleChange = (e) => {
        switch(e.target.name){
            case 'Empresa' :
                this.setState({ form: {
                    ...this.state.form,
                    Empresa:{
                        ...this.state.form.Empresa,
                        RUC : e.target.value.code,
                        RazonSocial : e.target.value.name
                    },
                    EmpresaRUC: e.target.value.code 
                    }
                })
                console.log(this.state);
                break;           
            case 'SincronizarRegistroVentas': 
                this.setState({ form: {
                    ...this.state.form,
                    [e.target.name]: !this.state.form.SincronizarRegistroVentas
                }
                })
                break;
            default :
                if (this.state.form.SincronizarRegistroVentas) {
                    this.setState({ form: {
                        ...this.state.form,
                        [e.target.name]: e.target.value,
                        RVConfiguracion: {
                            ...this.state.form.RVConfiguracion,
                            [e.target.name]: e.target.value,
                        }
                    }
                    }) 
                }
                else {
                    this.setState({ form: {
                        ...this.state.form,
                        [e.target.name]: e.target.value                        
                    }
                    })
                }
                break;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const{errores,...sinErrores} = this.state.form;
        const result = this.validate(sinErrores);
        if(Object.keys(result).length){
            this.setState({ errores: result });
        }else{
            this.CrearActualizarEntidad();
        }
    }

    CrearActualizarEntidad = () => {
        axios.post(url()+'ConfiguracionWeb',this.state.form)
        .then( (response) => {                        
            if(response.status==200){
                this.setState({ form: this.NuevoFormulario(), openModal: false });
                this.ListarConfiguracion();                
            }
        }).catch( (error) => {
            alert(error.message)
        })
    }

    render(){
    return(
        <div>
            <DTConfiguracion
                ListaEntidad = {this.state.listaEntidad}
                openModal = {() => this.setState( {openModal: true} ) }
                handleSelectRow = { (e) =>{                    
                    this.ObtenerConfiguracion(e);                    
                }}/>
            <ModalEntidad
                entidad = {this.state.form}
                openModal = {this.state.openModal}
                closeModal = {() => {
                    this.setState( {openModal: false, form: this.NuevoFormulario()} );
                    this.setState({errores: []});                    
                    // setEntidad(cEntidad)                    
                }}
                listaEmpresa = {this.state.listaEmpresa}
                handleChange = {this.handleChange}
                subirArchivo = {this.SubirArchivo}
                onSubmit = {this.onSubmit}
                borrarArchivo = {this.BorrarArchivo}
                Errores = {this.state.errores}/>
        </div>
    )}
}

export default Configuracion;