import React from 'react'
import { MouseEventHandler } from 'react'
import { Dropdown } from 'primereact/dropdown'

interface IProps {
    Paginado: {
        NumeroPagina: number,
        RegistrosPagina: number,
        NumeroPaginas: number,
        fil_EstadoRegla: number
    },
    HandlePreviousLL: MouseEventHandler,
    HandlePrevious: MouseEventHandler,
    HandleFollowing: MouseEventHandler,
    HandleFollowingLL: MouseEventHandler,
    RegistrosPagina: number,
    OnRegistroChange: any
}

interface IState {
    Registros: Array<number>
}

export class Paginator extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        this.state = {
            Registros: [
                10,
                20,
                30
            ]
        }
    }

    render() {
        return (
            <div className="p-paginator p-component p-paginator-bottom">
                {this.props.Paginado.NumeroPagina > 1 ?
                    <>
                        <button type="button" className="p-paginator-first p-paginator-element p-link" onClick={this.props.HandlePreviousLL}>
                            <span className="p-paginator-icon pi pi-angle-double-left"></span>
                        </button>
                        <button type="button" className="p-paginator-prev p-paginator-element p-link" onClick={this.props.HandlePrevious}>
                            <span className="p-paginator-icon pi pi-angle-left"></span>
                        </button>
                    </>
                    :
                    <>
                        <button type="button" className="p-paginator-first p-paginator-element p-link p-disabled" disabled>
                            <span className="p-paginator-icon pi pi-angle-double-left"></span>
                        </button>
                        <button type="button" className="p-paginator-prev p-paginator-element p-link p-disabled" disabled>
                            <span className="p-paginator-icon pi pi-angle-left"></span>
                        </button>
                    </>
                }
                <span className="p-paginator-pages">
                    <button type="button" className="p-paginator-page p-paginator-element p-link p-highlight">{this.props.Paginado.NumeroPagina}</button>
                </span>
                {this.props.Paginado.NumeroPagina < this.props.Paginado.NumeroPaginas ?
                    <>
                        <button type="button" className="p-paginator-next p-paginator-element p-link" onClick={this.props.HandleFollowing}>
                            <span className="p-paginator-icon pi pi-angle-right"></span>
                        </button>
                        <button type="button" className="p-paginator-last p-paginator-element p-link" onClick={this.props.HandleFollowingLL}>
                            <span className="p-paginator-icon pi pi-angle-double-right"></span>
                        </button>
                    </>
                    :
                    <>
                        <button type="button" className="p-paginator-next p-paginator-element p-link p-disabled" disabled>
                            <span className="p-paginator-icon pi pi-angle-right"></span>
                        </button>
                        <button type="button" className="p-paginator-last p-paginator-element p-link p-disabled" disabled>
                            <span className="p-paginator-icon pi pi-angle-double-right"></span>
                        </button>
                    </>
                }
                {<div>
                    <Dropdown
                        value={this.props.RegistrosPagina}
                        options={this.state.Registros}
                        onChange={this.props.OnRegistroChange} />
                </div>}
            </div>
        )
    }

}