import React,{Component} from 'react';

class Usuario extends Component{
    render(){
        return(
            <div>
                <h1>Usuario!</h1>
            </div>
        )
    }
}

export default Usuario;
