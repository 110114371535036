import React from 'react'
import { Modal } from 'react-responsive-modal'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown} from 'primereact/dropdown'
import { Checkbox} from 'primereact/checkbox'

export default function ModalUsuario(props){

    return (
        <Modal
        open={props.openModal}
        onClose={props.closeModal}
        center>
            <div className="headerModal">
                {props.usuario.PKID > 0 ? 
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Editar Usuario</h2>
                :
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Agregar Usuario</h2>}
            </div>
            <div className="modal-body p-mt-3">
                <div className="p-grid nested-grid">
                <div className="p-col-12">                        
                            <div className="p-grid p-fluid ">
                                <div className="p-col-6">                                    
                                    <InputText 
                                        name="Nombre" placeholder="Nombre"
                                        type="text"
                                        value={props.usuario ? props.usuario.Nombre : ''}
                                        onChange={props.handleChange}/>
                                    {props.Errores.Nombre && <small className="p-invalid p-d-block">{props.Errores.Nombre}</small>}
                                </div>
                                <div className="p-col-6">                                    
                                    <InputText 
                                        name="Apellido" placeholder="Apellido"
                                        type="text"
                                        value={props.usuario ? props.usuario.Apellido : ''}
                                        onChange={props.handleChange}/>
                                    {props.Errores.Apellido && <small className="p-invalid p-d-block">{props.Errores.Apellido}</small>}
                                </div>
                            </div>
                        
                    </div>                   
                    <div className="p-col-12">                        
                            <div className="p-grid p-fluid ">
                                <div className="p-col-6">                                    
                                    <InputText 
                                        name="IDUsuario" placeholder="Usuario"
                                        type="text"
                                        value={props.usuario ? props.usuario.IDUsuario : ''}
                                        onChange={props.handleChange}/>
                                    {props.Errores.IDUsuario && <small className="p-invalid p-d-block">{props.Errores.IDUsuario}</small>}
                                </div>
                                <div className="p-col-6">
                                    <div 
                                        className="p-field-checkbox" 
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'}}>
                                        <Checkbox 
                                            name="Administrador"
                                            inputId="binary" 
                                            checked={props.usuario ? props.usuario.Administrador : false}
                                            onChange={props.handleChange}/>
                                                <label 
                                                    className="p-d-block"
                                                    style={{
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'}}
                                                >Administrador</label>
                                    </div>
                                </div>
                            </div>                        
                    </div>
                    <div className="p-col-12">                        
                        <div className="p-grid p-fluid ">
                                <div className="p-col-6">                                    
                                    <Dropdown 
                                        value={{
                                            code: props.usuario.Empresa.RUC,
                                            name: props.usuario.Empresa.RazonSocial}}
                                        options={props.listaEmpresa} 
                                        onChange={props.handleChange}
                                        name="Empresa" 
                                        filter showClear
                                        optionLabel="name" 
                                        placeholder="Seleccione Empresa"/>
                                    {props.Errores.Empresa && <small className="p-invalid p-d-block">{props.Errores.Empresa}</small>}
                                </div>      
                                <div className="p-col-6">                                    
                                    <InputText 
                                        name="Correo" placeholder="Correo"
                                        type="email"
                                        value={props.usuario ? props.usuario.Correo : ''}
                                        onChange={props.handleChange}/>
                                    {props.Errores.Correo && <small className="p-invalid p-d-block">{props.Errores.Correo}</small>}
                                </div>                      
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-d-flex footer p-ml-auto">
                <Button 
                    type="submit"
                    label="Guardar" 
                    style={{
                        background:'#4D80E4',
                        border: '1px solid #4D80E4'}}
                    icon="pi pi-check" 
                    onClick={props.onSubmit}
                    className="p-ml-auto"
                    autoFocus />
            </div>
        </Modal>
    )
}