import React, { useRef, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown} from 'primereact/dropdown'
import { Checkbox} from 'primereact/checkbox'
import { FileUpload } from 'primereact/fileupload'
//import { Tag } from 'primereact/tag';

export default function ModalConfiguracion(props){

    const file = useRef();

    const [disabled, setDisabled] = useState(true);

    const itemTemplate = (file, props) => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-ai-center p-flex-wrap">
                    <div className="p-d-flex p-ai-center" style={{width: '25%'}}>
                        <img alt={file.Nombre} role="presentation" src={`data:image/png;base64,${file.Archivo}`} width={100} />                        
                    </div>                    
                    <div className="p-d-flex p-ai-center" style={{width: '25%'}}>
                        <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                            { file.Nombre + '.' + file.Extension }
                            <small>{new Date().toLocaleDateString()}</small>
                        </span>
                    </div>
                    <div className="p-d-flex p-ai-center" style={{width: '25%'}}>
                        <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                            {file.Tamanio/1000} KB
                        </span>
                    </div>
                    {/* <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" /> */}
                    <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger p-ml-auto" onClick={() => props.borrarArchivo()} />
                </div>
            </React.Fragment>
        )
    }

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 2
            }}
        />
    );

    return (
        <Modal
        open={props.openModal}
        onClose={props.closeModal}
        center>
            <div className="headerModal">
                {props.entidad.ID > 0 ? 
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Editar</h2>
                :
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Agregar</h2>}
            </div>
            <div className="modal-body p-mt-3">
                <div className="p-grid nested-grid">
                <div className="p-col-12">                        
                        <div className="p-grid p-fluid ">
                                <div className="p-col-6">                                    
                                    <Dropdown 
                                        value={{
                                            code: props.entidad.Empresa ? props.entidad.Empresa.RUC : '',
                                            name: props.entidad.Empresa ? props.entidad.Empresa.RazonSocial : '' }}
                                        options={props.listaEmpresa} 
                                        onChange={props.handleChange}
                                        name="Empresa" 
                                        filter showClear
                                        optionLabel="name" 
                                        placeholder="Seleccione Empresa"/>
                                    {props.Errores.Empresa && <small className="p-invalid p-d-block">{props.Errores.Empresa}</small>}
                                </div>      
                                <div className="p-col-6">                                    
                                    <InputText 
                                        name="NombrePublicacion" placeholder="Publicacion"
                                        type="email"
                                        value={props.entidad ? props.entidad.NombrePublicacion : ''}
                                        onChange={props.handleChange}/>
                                    {props.Errores.NombrePublicacion && <small className="p-invalid p-d-block">{props.Errores.NombrePublicacion}</small>}
                                </div>                      
                        </div>
                </div>
                <div className="p-col-12">
                    <ColoredLine color="blue" />
                </div>
                <div className="p-col-12">        
                    <div className="p-grid p-fluid">                            
                            <div className="p-col-4">
                                <FileUpload 
                                    ref={file}
                                    mode="basic" 
                                    name="file" 
                                    auto 
                                    customUpload uploadHandler={(e) => props.subirArchivo(e,file)}
                                    className="p-ml-auto"
                                    maxFileSize={200000} 
                                    //multiple 
                                    accept="image/*" 
                                    invalidFileSizeMessageSummary="{0}: Invalid file size" 
                                    invalidFileSizeMessageDetail="maximum upload size is 200 KB."
                                    chooseLabel="Seleccionar Logo:" />
                                    {props.Errores.ArchivoAdjunto && <small className="p-invalid p-d-block">{props.Errores.ArchivoAdjunto}</small>}
                            </div>                    
                            {props.entidad.ArchivoAdjunto && props.entidad.ArchivoAdjunto.Nombre ?
                            <div className="p-col-8">
                                    {itemTemplate(props.entidad.ArchivoAdjunto, props)}                                
                            </div>
                            :
                            <></>
                            }
                    </div>                
                </div>           
                <div className="p-col-12">
                    <ColoredLine color="blue" />
                </div>
                <div className="p-col-12">
                            <div className="p-grid p-fluid ">                                
                                <div className="p-col-6">
                                    <div 
                                        className="p-field-checkbox" 
                                        style={{
                                            display: 'flex',                                            
                                            alignItems: 'center'}}>
                                        <Checkbox 
                                            name="SincronizarRegistroVentas"
                                            inputId="binary" 
                                            checked={props.entidad ? props.entidad.SincronizarRegistroVentas : false}
                                            onChange={props.handleChange}/>
                                                <label 
                                                    className="p-d-block"
                                                    style={{
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'}}
                                                >SincronizarRegistroVentas</label>
                                    </div>
                                </div>
                            </div>                        
                </div>
                
                <div className="p-col-12" style={{
                                opacity: props.entidad.SincronizarRegistroVentas ? 1 : 0.25,
                                pointerEvents: props.entidad.SincronizarRegistroVentas ? "initial" : "none" 
                        }} >
                            <div className="p-grid p-fluid ">
                                <div className="p-col-3"> 
                                <small>Hora Inicio</small>                                   
                                    <InputText 
                                        name="HoraInicio" placeholder="HoraInicio"
                                        type="number"
                                        value={props.entidad.RVConfiguracion ? props.entidad.RVConfiguracion.HoraInicio : 0}
                                        onChange={props.handleChange}/>
                                    {props.Errores.HoraInicio && <small className="p-invalid p-d-block">{props.Errores.HoraInicio}</small>}
                                </div>
                                <div className="p-col-3">         
                                <small>Hora Fin</small>                           
                                    <InputText 
                                        name="HoraFin" placeholder="HoraFin"
                                        type="number"
                                        value={props.entidad.RVConfiguracion ? props.entidad.RVConfiguracion.HoraFin : 0}
                                        onChange={props.handleChange}/>
                                    {props.Errores.HoraFin && <small className="p-invalid p-d-block">{props.Errores.HoraFin}</small>}
                                </div>
                                <div className="p-col-3">                                    
                                <small>Fecha Mínima</small>
                                    <InputText 
                                        name="FechaMinima" placeholder="FechaMinima"
                                        type="date"
                                        value={ props.entidad.RVConfiguracion.FechaMinima }
                                        onChange={props.handleChange}/>
                                    {props.Errores.HoraFin && <small className="p-invalid p-d-block">{props.Errores.HoraFin}</small>}
                                </div>
                                <div className="p-col-3">                                    
                                <small>Cantidad Mínima</small>
                                    <InputText 
                                        name="CantidadMinima" placeholder="CantidadMinima"
                                        type="number"
                                        value={props.entidad.RVConfiguracion ? props.entidad.RVConfiguracion.CantidadMinima : 0}
                                        onChange={props.handleChange}/>
                                    {props.Errores.CantidadMinima && <small className="p-invalid p-d-block">{props.Errores.CantidadMinima}</small>}
                                </div>
                            </div>                        
                </div>

                </div>
            </div>
            <div className="p-d-flex footer p-ml-auto">
                <Button 
                    type="submit"
                    label="Guardar" 
                    style={{
                        background:'#4D80E4',
                        border: '1px solid #4D80E4'}}
                    icon="pi pi-check" 
                    onClick={props.onSubmit}
                    className="p-ml-auto"
                    autoFocus />
            </div>
        </Modal>
    )
}