import React,{useEffect, useState} from 'react'
import axios from 'axios'
import {getDecode} from '../../Services/LocalStorage'
import { initAxiosInterceptors, url } from '../../Services/Axios'
import DTUsuarios from '../Components/DTUsuarios'
import ModalUsuario from '../Components/ModalUsuario'

import '../Assets/Modal.css'
import 'react-responsive-modal/styles.css'

initAxiosInterceptors();

export default function Usuario(){

    const validate = values =>{
        const errores ={}
        if(!values.IDUsuario){
            errores.IDUsuario  = 'Usuario es requerido';
        }
        if(!values.Empresa.RUC){
            errores.Empresa = 'Empresa es requerida'
        }
        return errores;
    }

    const cUsuario = {
        ID : 0,
        IDUsuario : '',
        IsNuevo : false,
        Administrador : false,        
        Empresa : {   
            RUC : '',
            RazonSocial : ''
        },
        Nombre: '',
        Apellido: '',
        Correo: '',
        RUC: '',
    }

    const [ openModal, setOpenModal ] = useState(false)
    const [ listaUsuarios, setListaUsuarios ] = useState([])
    const [ usuario, setUsuario ] = useState(cUsuario)
    const [ listaEmpresa, setListaEmpresa] = useState([])
    const [ errores, setErrores ] = useState({})

    useEffect(function (){
        ListarUsuarios();
        ListarEmpresa();
    },[])

    const ListarUsuarios = () => {
        axios.get(url()+'Usuario/Listar?IDUsuario='+getDecode().sub)
        .then((response) => {
            if(response.status==200){
                setListaUsuarios(response.data);
            }else{
                alert(response.data.data);
            }
        }).catch((error) => {
            console.log("error"+error);
            if (error.response) {
                if (error.response.data) alert(error.response.data.error.message);
                console.log("Imprimiendo el error");
                console.log("Error response"+error.response);
            }else {
                console.log(error);
                alert("Error desconocido, consulte con el administrador.");
            }
        })
    }

    const ListarEmpresa = () => {
        axios.get(url()+'Empresa/Listar')
        .then((response) => {
            if(response.status==200){
                var aEmpresa = []
                Object.keys(response.data).forEach(function(key){
                    var iEmpleado = {
                        code: response.data[key].RUC,
                        name: response.data[key].RazonSocial,
                    }
                    aEmpresa.push(iEmpleado);
                });
                setListaEmpresa(aEmpresa);
            }else{
                alert(response.data.data);
            }
        }).catch((error) => {
            alert(error);
        })
    }

    const handleChange = (e) => {
        switch(e.target.name){
            case 'Empresa' :
                setUsuario({
                    ...usuario,
                    Empresa:{
                        ...usuario.Empresa,
                        RUC : e.target.value.code,
                        RazonSocial : e.target.value.name
                    },
                    RUC: e.target.value.code
                })
                break;           
            case 'Administrador': 
                setUsuario({
                    ...usuario,
                    [e.target.name]: !usuario.Administrador
                })
                break;
            default :
                setUsuario({
                    ...usuario,
                    [e.target.name]: e.target.value
                })
                break;
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const{errores,...sinErrores} = usuario;
        const result = validate(sinErrores);
        if(Object.keys(result).length){
            return setErrores(result)
        }else{
            CrearActualizarUsuario()
        }
    }

    const CrearActualizarUsuario = () => {
        axios.post(url()+'Usuario',usuario)
        .then( (response) => {                        
            if(response.status==200){
                setOpenModal(false);
                setUsuario(cUsuario);
                ListarUsuarios();
                ListarEmpresa();
            }
        }).catch( (error) => {
            alert(error.message)
        })
    }

    return(
        <div>
            <DTUsuarios
                ListaUsuarios = {listaUsuarios}
                openModal = {() => setOpenModal(true)}
                handleSelectRow = { (e) =>{
                    setUsuario(e)
                    setOpenModal(true)
                }}/>
            <ModalUsuario
                usuario = {usuario}
                openModal = {openModal}
                closeModal = {() => {
                    setOpenModal(false)
                    setUsuario(cUsuario)
                    setErrores({})
                }}
                listaEmpresa = {listaEmpresa}
                handleChange = {handleChange}
                onSubmit = {onSubmit}
                Errores = {errores}/>
        </div>
    )
}