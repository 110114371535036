import React,{useEffect, useState} from 'react'
import axios from 'axios'
import { initAxiosInterceptors, url } from '../../Services/Axios'
import DTEmpleados from '../Components/DTEmpleados'
import ModalEmpleado from '../Components/ModalEmpleado'

import '../Assets/DTable.css'

initAxiosInterceptors();

export default function Empleado(){

    const validarEmpleado = values =>{
        const errores ={}
        if(!values.Nombre){
            errores.Nombre = 'Nombre es requerido'
        }
        if(!values.Area.PKID){
            errores.Area = 'Area es requerido'
        }
        if(!values.CorreoElectronico){
            errores.CorreoElectronico  = 'CorreoElectronico es requerido';
        }
        return errores;
    }

    const cEmpleado = {
        PKID : 0,
        Codigo : '',
        Nombre : '',
        Area : {
            PKID : 0,
            Codigo : '',
            Nombre : ''
        },
        CorreoElectronico: ''
    }

    const [ openModal, setOpenModal ] = useState(false)
    const [ listaEmpleado, setListaEmpleado ] = useState([])
    const [ listaArea, setListaArea ] = useState([])
    const [ empleado, setEmpleado ] = useState(cEmpleado)
    const [ errores, setErrores ] = useState({})

    useEffect(function (){
        ListarEmpleado()
        ListarArea()
    },[])

    const ListarEmpleado = () => {
        axios.post(url()+'Empleado/ListarEmpleados')
        .then((response) => {
            if(response.data.success){
                setListaEmpleado(JSON.parse(response.data.data))
            }else{
                alert(response.data.data)
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const ListarArea = () => {
        axios.post(url()+'Area/ListarAreas')
        .then( (response) => {
            if(response.data.success){
                var aArea = []
                Object.keys(JSON.parse(response.data.data)).forEach(function(key){
                    var iArea = {
                        code: JSON.parse(response.data.data)[key].PKID,
                        name: JSON.parse(response.data.data)[key].Nombre,
                    }
                    aArea.push(iArea);
                });
                setListaArea(aArea)
            }else{
                alert(response.data.data)
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    const handleChange = (e) => {
        switch(e.target.name){
            case 'Area' :
                setEmpleado({
                    ...empleado,
                    Area:{
                        ...empleado.Area,
                        PKID : e.target.value.code,
                        Nombre : e.target.value.name
                    }
                })
                break
            default :
                setEmpleado({
                    ...empleado,
                    [e.target.name]: e.target.value
                })
                break
        }
        
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const{errores,...sinErrores} = empleado
        const result = validarEmpleado(sinErrores)
        if(Object.keys(result).length){
            return setErrores(result)
        }else{
            if(validarExpresion(empleado.CorreoElectronico))
                CrearActualizarEmpleado()
            else
                alert("No es un correo valido")
        }
    }

    const validarExpresion = (CorreoElectronico) => {
        var Band = false
        var regex = new RegExp(/^([a-z0-9_\.-]+)@uniflex\.com.pe$/)
        if(regex.test(CorreoElectronico)) Band = !Band
        return Band
    }

    const CrearActualizarEmpleado = () => {
        axios.post(url()+'Empleado/CrearActualizarEmpleado',empleado)
        .then( (response) => {
            alert(response.data.data)
            if(response.data.success){
                setOpenModal(false)
                setEmpleado(cEmpleado)
                ListarEmpleado()
                ListarArea()
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    return(
        <div>
            <DTEmpleados
                ListaEmpleado = {listaEmpleado}
                openModal = {() => setOpenModal(true)}
                handleSelectRow = { (e) =>{
                    setEmpleado(e)
                    setOpenModal(true)
                }}/>
            <ModalEmpleado
                openModal = {openModal}
                closeModal = {() => {
                    setErrores({})
                    setEmpleado(cEmpleado)
                    setOpenModal(false)
                }}
                listaArea = {listaArea}
                handleChange = {handleChange}
                empleado = {empleado}
                onSubmit = {onSubmit}
                Errores = {errores}/>
        </div>
    )
}