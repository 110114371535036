import React, {Component} from 'react'
import './../assets/css/BackDrop.css'

class BackDrop extends Component {
    render() {
        return (
            <div 
                className="backDrop"
                onClick={this.props.click}
            >
            </div>
        );
    }
}

export default BackDrop;