import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'

export default function DTSincronizacion(props){

    const SincronizarRegistroVentas = (rowData) => {
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={rowData.SincronizarRegistroVentas}
                    disabled/>
            </div>
        )
    }

    const header = (
        <div className="p-d-flex p-jc-between">
            <h4>Sincronización de registros</h4>
            <Button 
                label="Agregar" 
                onClick={props.openModal} 
                autoFocus/> 
        </div>
    );

    return(
        <div className="datatable-responsive-demo">
            <DataTable 
                value={props.ListaEntidad} 
                className="p-datatable-responsive-demo" 
                onSelectionChange={e => props.handleSelectRow(e.value)}  
                selectionMode="single" 
                paginator 
                rows={8} 
                header={header}>
                <Column field="RUC" header="RUC"/>
                <Column field="RazonSocial" header="Razón Social"/>
                <Column field="SincronizarRegistroVentas" body={SincronizarRegistroVentas} header="Registro Ventas" />
            </DataTable>
        </div>
    )
}