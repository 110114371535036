import jwt from 'jwt-decode'

const TOKEN_KEY = 'TOKEN-UN1FL3X-EMISION'
const REFRESH_TOKEN_KEY = 'REFRESH-TOKEN-UN1FL3X-EMISION'

export function setToken(access_token,refresh_token){
    localStorage.setItem(TOKEN_KEY,access_token);
    localStorage.setItem (REFRESH_TOKEN_KEY,refresh_token); 
}

export function getToken(){
    return localStorage.getItem(TOKEN_KEY);
}

export function getRefreshToken(){
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function deleteToken(){
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function getDecode(){
    if(getToken())
        return jwt(getToken());
    else
        return null
}

export function getAuthenticado(){
    //if(getToken() && getDecode().Area === 'Desarrollo'){
    if(getToken()){
        return true;
    }else{
        return false;
    }
}

export function getAuthenticadoImp(){
    if(getToken() && getDecode().Area === 'Implementacion'){
        return true;
    }else{
        return false;
    }
}

export function EliminarTodo(){
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    //localStorage.removeItem(PROYECTO)    
}